.list {
  display: flex;
  align-items: center;
  position: relative;
}

.list__inner::before {
  content: "";
  position: absolute;
  top: 0;
  left: -1rem;
  right: -1rem;
  height: 100%;
  background: var(--color-list);
  z-index: -1;
  transition: opacity 0.2s linear;
  opacity: 0;
}

.list__inner {
  text-decoration: none;
  width: calc(100% + 2rem);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  cursor: pointer;
  z-index: 1;
  margin: 0 -1rem;
  color: var(--color-font);
  text-align: left;
  background-color: transparent;
  border-bottom: 2px solid var(--color-list);
}

@media (hover: hover) {
  .list__inner:hover::before {
    opacity: 1;
  }
}

@media (hover: hover) {
  .list__inner:hover {
    color: var(--color-font);
  }
}

.list[dragging] > .list__inner::before,
.list__inner:focus::before,
.list__inner:active::before {
  opacity: 1;
}

.list__inner:focus,
.list__inner:focus {
  color: var(--color-font);
}

@media (min-width: 1280px) {
  .list--active::after {
    content: "";
    position: absolute;
    right: calc(-1rem - 30px);
    top: 50%;
    transform: translateY(-50%);
    width: 0px;
    height: 0px;
    border-top: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid var(--color-list);
  }
}

.list__inner--disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: initial;
}
