.unit__subline {
  padding-top: 1rem;
}

@media (min-width: 1280px) {
  .unit__subline:first-of-type {
    padding-top: 0;
    margin-top: 1rem;
  }
}

.unit__subline--space-before {
  margin-top: 0.5rem;
}

.unit__points {
  font-size: 1rem;
  font-weight: 400;
}

.unit__name {
  margin-right: 0.5rem;
}

.unit__points {
  white-space: nowrap;
}

.unit__empty {
  text-align: center;
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.unit__notes {
  margin-top: calc(1rem + 3px);
  margin-bottom: 0.5rem;
  font-style: italic;
  display: flex;
  align-items: flex-start;
}

@media (min-width: 1280px) {
  .unit__notes {
    margin-bottom: 1.5rem;
  }
}

.unit__notes-icon {
  margin-right: 5px;
  margin-top: 1px;
}

.unit__strength {
  margin-top: 1rem;
}

.unit__link .list__inner label {
  cursor: pointer;
}

.unit__command-list {
  margin-bottom: 0.5rem;
}

.unit__special-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 0;
  margin-top: 1rem;
}

.unit__magic-headline {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.unit__magic-icon {
  margin-left: 5px;
}

.editor__error {
  color: var(--color-error);
  font-weight: bold;
}

.unit__detachments-headline {
  margin-bottom: 1rem;
}

.unit__detachments-header {
  cursor: initial;
  background: linear-gradient(
    90deg,
    var(--color-list) 0%,
    rgba(232, 221, 203, 1) 50%,
    var(--color-list) 100%
  );
}

.unit__detachments-header::before,
.unit__detachments::before {
  content: none;
}

.unit__detachments {
  cursor: initial;
  border-bottom: 0 none;
}

.unit__detachments:not(:first-child) {
  border-top: 2px solid var(--color-list);
}

.unit__detachments .input {
  margin-bottom: 0;
  width: 50px;
  padding-right: 0;
}

.unit__detachments > span {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 1rem;
  min-width: 0;
}

.unit__detachments > span > b {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.unit__detachments-wrapper {
  flex-wrap: wrap;
}

.unit__detachments-section {
  width: 100%;
}

.unit__hr {
  margin: 0 -1rem;
  border: 0 none;
}

.unit__hr--space-top {
  margin-top: 1.5rem;
}

@media (max-width: 1279px) {
  .unit__bsb {
    margin-top: 1rem;
  }
}

.unit__rule {
  background-color: transparent;
  font-size: 1rem;
  font-family: var(--font-family);
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  color: var(--color-headline);
  transition: color 0.2s linear;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  max-width: 100%;
}

.unit__rule:focus,
.unit__rule:active {
  color: var(--color-button);
}

@media (hover: hover) {
  .unit__rule:hover {
    color: var(--color-button);
  }
}

.unit__label-text {
  flex-grow: 1;
}

.unit__rules {
  padding: 0;
  margin-left: 0.5rem;
  height: auto;
}

.unit__default-equipment {
  margin-bottom: 0.5rem;
}

.unit__rule-wrapper {
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
}

.unit__command-option-hr {
  margin: 0 -1rem 0.5rem -1rem;
}

.unit__header-rule-icon {
  position: relative;
  margin-right: 0.5rem;
}

.unit__option-note {
  margin: -0.8rem 0 0.8rem 1.4rem;
  line-height: normal;
  font-size: 0.9em;
  font-style: italic;
}

.unit__option-note--stackable {
  margin-left: 1rem;
  margin-right: 2.2rem;
}

.unit__option-note--conditionnal {
  margin-left: 1.8rem;
}

.unit__option-note--items {
  margin-left: 0;
  margin-bottom: 0.5rem;
}

.unit__option-note--detachment {
  margin: 0.3rem 0 0.5rem 0;
}
