.header {
  background-color: var(--color-header);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  height: 62px;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}

.column-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  align-items: center;
  position: relative;
  width: calc(100% + 2rem);
  margin: 0 -1rem 1rem -1rem;
  height: 69px;
}

.column-header h1 {
  font-family: var(--font-family);
}

.header__text {
  text-align: center;
  white-space: nowrap;
  min-width: 0;
  flex-grow: 1;
}

.header__text .header__name-text,
.header__text .header__points {
  color: var(--color-font-light);
}

.column-header .header__name-text {
  color: var(--color-header);
}

.column-header .header__points {
  color: var(--color-font);
}

.header__name {
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__name-link,
.header__name-link:visited {
  color: var(--color-font-light);
  text-decoration: none;
}

.header__name-link:hover,
.header__name-link:visited:hover {
  color: var(--color-font-light);
  text-decoration: underline;
}

.header__name-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header__points {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__points .icon {
  margin-left: 5px;
}

.header__more-button {
  position: relative;
}

.header__more-button::before {
  content: "";
  position: fixed;
  top: 62px;
  background-color: rgba(0, 0, 0, 0);
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  cursor: default;
}

.header__more-button::after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.header__more {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--color-header-menu);
  padding: 0.4rem 0;
  z-index: 2;
  border-radius: 0px 0px 0px 5px;
}

@media (min-width: 1280px) {
  .header__more--secondary-navigation {
    border-radius: 5px;
  }
}

.header__more a {
  width: 100%;
  justify-content: flex-start;
}

.header__empty-icon {
  width: 54px;
}

.header__checkbox {
  margin-bottom: 0 !important;
  color: var(--color-font-light);
  padding: 5px 1rem;
}

.header__filter-description {
  color: var(--color-font-light);
  padding: 5px 1rem;
}
