.export .button {
  display: flex;
}

.export__text {
  background-color: var(--color-white);
  display: block;
  padding: 1rem;
  font-size: 1rem;
  border: 0 none;
  min-width: 100%;
  max-width: 100%;
  min-height: 20rem;
}

.export__error {
  margin-bottom: 1rem;
  text-align: center;
  color: var(--color-error);
}

.checkbox.export__visible-checkbox,
.radio.export__visible-checkbox {
  margin-bottom: 0;
}

.export__description {
  margin-bottom: 1rem;
}

.export__radio-description {
  margin-left: 1rem;
}

.export__subtitle {
  margin-top: 2rem;
}

.export__sub-subtitle {
  margin-top: 1rem;
}
