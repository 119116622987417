.icon {
  display: inline-block;
  width: 22px;
  height: 22px;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  flex-shrink: 0;
}

.icon--red {
  stroke: var(--color-error);
  fill: var(--color-error);
}

.icon--green {
  stroke: var(--color-success);
  fill: var(--color-success);
}
