.editor__list a,
.editor__list button {
  display: block;
}

.editor__list p {
  margin-top: 4px;
  font-size: 13px;
}

.editor__list-inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 22px;
}

.editor__list-inner b {
  margin-right: 0.5rem;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.editor__list-inner .checkbox__points {
  margin-left: 0;
}

.editor__list-inner > span {
  margin-right: 4px;
}

.editor__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: var(--color-list);
  margin: 0 -1rem 0 -1rem;
  background: linear-gradient(
    90deg,
    var(--color-list) 0%,
    rgba(232, 221, 203, 1) 50%,
    var(--color-list) 100%
  );
}

.editor__header h2 {
  margin: 0;
  margin-right: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.editor__header .icon {
  margin-left: 5px;
}

.editor__section {
  margin-bottom: 3rem;
}

.editor__section i {
  white-space: nowrap;
}

.editor__points {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.editor__soon {
  text-align: center;
  font-style: italic;
  margin-top: 0.5rem;
}

.editor__delete-dialog {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
