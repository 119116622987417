.expandable {
  margin-bottom: 1rem;
}

.expandable--no-margin {
  margin-bottom: 0;
}

.expandable__summary {
  cursor: pointer;
  user-select: none;
  font-size: 1rem;
  font-weight: 700;
  padding-bottom: 0.5rem;
  color: var(--color-headline);
  line-height: 1.5;
  display: list-item;
  transition: color 0.2s linear;
}

.expandable__summary:focus {
  outline: 1px dashed var(--color-headline);
}

@media (hover: hover) {
  .expandable__summary:hover {
    color: var(--color-headline);
  }
}

.expandable__summary--normal {
  font-weight: normal;
  color: var(--color-headline);
}
