.error-message {
  color: var(--color-error);
  display: flex;
  align-items: center;
}

.error-message--space-before {
  margin-top: 1rem;
}

.error-message--space-after {
  margin-bottom: 1rem;
}

.error-message__icon {
  margin-right: 5px;
}
