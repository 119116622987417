.datasets {
  margin: 2rem auto 0;
  padding-bottom: 0;
  min-height: 0;
}

.datasets h3 {
  line-height: 1.5;
}

.datasets__edit-headline {
  padding: 1rem;
  margin: 0 -1rem;
  border-bottom: 2px solid var(--color-list);
}

.datasets__info {
  margin-bottom: 2rem;
}

.datasets__info li {
  list-style: disc inside;
  margin-bottom: 0.5rem;
  line-height: 1.5;
}

.datasets__info h3 {
  margin-bottom: 0;
}

.datasets .list:first-child .list__inner {
  border-top: 2px solid var(--color-list);
}

.datasets__paragraph {
  margin-bottom: 1rem;
}

@media (min-width: 1280px) {
  .datasets__columns {
    display: flex;
    justify-content: center;
  }

  .column.datasets__column {
    padding: 0 2rem 2rem 2rem;
    width: 25%;
  }

  .datasets__info {
    margin-left: 1rem;
  }
}

@media (max-width: 1279px) {
  .column.datasets__column {
    padding: 0 2rem 2rem 2rem;
    width: calc(100% + 2rem);
    margin: 0 -1rem;
  }
}

.datasets__output {
  white-space: nowrap;
  min-width: 100%;
  max-width: 100%;
  margin-top: 1rem;
  padding: 0.5rem;
}

.datasets__unit-type,
.datasets__unit {
  margin: 0 -1rem;
  padding: 1rem 1rem 0.5rem 1rem;
  border-bottom: 2px solid var(--color-list);
}

.datasets__unit > .expandable__summary {
  font-weight: normal;
}

.dataset__unit-header {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 2rem);
}

.dataset__unit-header .button {
  padding: 0;
  height: 22px;
  margin-left: 0.5rem;
}

.dataset__unit-header > span {
  flex-shrink: 0;
}

.datasets__preview {
  max-width: 25rem;
  pointer-events: none;
}
