.select {
  font-family: var(--font-family);
  font-size: 1rem;
  color: var(--color-font);
  background-color: var(--color-white);
  padding: 0 1rem;
  transition: box-shadow 0.2s linear;
  border: 0 none;
  width: 100%;
  margin: 0 1rem 1rem 0;
  height: 48px;
  cursor: pointer;
}

@media (hover: hover) {
  .select:hover {
    box-shadow: 0 0 10px 2px var(--color-list);
  }
}

.select:focus,
.select:active {
  box-shadow: 0 0 10px 2px var(--color-list);
}

.select--spaceTop {
  margin-top: 1rem;
}

.select--spaceBottom {
  margin-bottom: 1rem;
}
