@media print {
  body {
    background: #fff !important;
  }

  .hide-for-printing {
    display: none;
  }
}

@media screen {
  .print {
    background-color: #fff;
    margin: 1rem;
  }

  .hide-for-printing {
    padding: 5rem 1rem 0 1rem;
  }
}

.print {
  font-size: 14px;
  padding: 1rem;
}

.print h1 {
  text-align: center;
  margin-bottom: 0;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.print h2 {
  font-size: 1.2rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.print ul {
  margin-left: 0.5rem;
}

.print li {
  margin-bottom: 1rem;
}

.print__points {
  margin-left: 5px;
  font-weight: normal;
  font-size: 1rem;
  font-family: var(--font-family);
}

.print__strength {
  font-weight: normal;
}

.print__subheader {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--color-font);
  padding-bottom: 1rem;
}

.print-footer {
  padding-top: 1rem;
  margin-top: 3rem;
  border-top: 1px solid var(--color-font);
}

.print-footer p {
  text-align: center;
}

.print__special-rules {
  margin-top: 5px;
}

.print__custom-note {
  margin-top: 5px;
}
