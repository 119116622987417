.add__name {
  margin-right: 0.5rem;
  flex-grow: 1;
}

.add__list-inner {
  margin-right: calc(-2rem - 22px);
  padding-right: 3rem;
}

.add__rules-icon {
  margin-left: 1rem;
  z-index: 1;
}

.add__army-composition {
  text-align: right;
  font-weight: bold;
  font-style: italic;
}
