.changelog li {
  list-style: disc inside;
  margin-bottom: 0.5rem;
  line-height: 1.5;
}

.changelog__list {
  margin-bottom: 2rem;
}

.changelog__outdated {
  text-decoration: line-through;
}
