.magic__type {
  margin-top: 1rem;
  margin-bottom: 4px;
}

.magic__header-points {
  color: var(--color-font);
}

.header .magic__header-points {
  color: var(--color-font-light);
}

.magic__label-text {
  flex-grow: 1;
}

.magic__rules {
  padding: 0;
  margin-left: 1rem;
  height: auto;
}
