.game-view {
  margin-bottom: 2rem;
}

.game-view__details {
  padding-top: 5px;
  padding-left: 0.5rem;
}

.game-view__list-inner {
  display: block;
  padding: 2rem 1rem;
}

.game-view li:last-of-type .game-view__list-inner {
  border-bottom: 0 none;
}

.game-view__list-inner::before {
  display: none;
}

.game-view__list-inner {
  cursor: initial;
}

.game-view__points {
  margin-left: 5px;
  font-weight: normal;
  font-size: 1rem;
  font-family: var(--font-family);
}

.game-view__strength {
  font-weight: normal;
}

.game-view__special-rules {
  margin-top: 10px;
}

.game-view__stats {
  margin-top: 10px;
}

.game-view__section {
  margin-bottom: 1rem;
}

.game-view__name {
  display: inline-flex;
  align-items: center;
}

.game-view__rule-icon {
  margin-left: 0.5rem;
}

.game-view__victory-points {
  margin-top: 2rem;
}

.game-view__victory-button {
  margin-right: 1rem;
  margin-top: 1.5rem;
  font-weight: bold;
  border: 1px solid var(--color-button-secondary);
}

.game-view__custom-note-label {
  margin-top: 1rem;
}

.game-view__detachment {
  display: inline-flex;
  align-items: center;
}

.game-view__detachment .input {
  margin-bottom: 0;
}

.game-view__detachment-label {
  padding-bottom: 0;
}

.input.game-view__custom-note-input {
  margin-bottom: 0;
}
