.home__empty {
  text-align: center;
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.home__list-item {
  overflow-x: hidden;
  flex-grow: 1;
}

.home__headline {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 0.5rem;
  margin-bottom: 0;
}

.home__logo {
  display: block;
  margin: 2rem auto;
}

.home__description {
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 0.5rem;
  font-style: italic;
}

.home__points {
  margin-top: 0.5rem;
}

.home__info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home__banner-link:focus {
  outline: 2px solid var(--color-list);
}

.home__banner-image {
  display: block;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  max-width: 30rem;
}
